let content = {
    landing: {
        title: "Un genre de spectacle",
    },
    paragraph1: {
        title: "Spectacle en (dé)construction…",
        content:
            "L’inégalité des genres et le sexisme sont aujourd’hui encore des problèmes de société. Ceux-ci peuvent prendre plusieurs formes, certaines plus évidentes et visibles que d’autres. Que ce soit au travail, dans les médias, à l’école ou même chez soi, il est important d’être conscient.e.s des actes sexistes, car mêmes s’ils peuvent parfois paraître négligeables, ils ont toujours des effets délétères.",
    },
    hero_solution: {
        title: "Notre solution",
    },
    paragraph2: {
        title: "Chaque spectacle est improvisé, donc unique.",
        content:
            "Impro Impact propose des spectacles interactifs adaptés selon le public. En collaboration avec l’audience, nous présentons des tableaux improvisés permettant de déceler les problématiques de genre dans la vie de tous les jours. Ensemble, nous trouvons des solutions concrètes neutraliser le sexisme au quotidien.",
    },
    hero_orga: {
        title: "Porteuses du projet",
    },
    organisers: {
        aurelygia: {
            title: "Lygia et Aurélia",
            content:
                "Lygia Pavitt (à gauche) est fraîchement diplômée d’un Master de Psychologie sociale à l’UNIGE, qu’elle a conclu avec un mémoire sur les effets néfastes du sexisme bienveillant sur l’aspiration au leadership chez les femmes. Improvisatrice depuis de nombreuses années, elle forme depuis 4 ans des comédien·ne·s aux AC de l'UNIGE et à la F!G. Aurélia Platon (à droite) est enseignante au primaire, spécialiste en droits de l’enfant, animatrice de dialogues philosophiques et comédienne. Elle participe depuis 2017 à divers projets théâtraux, portant à chaque fois, au travers de ces derniers, des questionnements sur l’identité féminine.",
        },
    },
    hero_atelier: {
        title: "Atelier Genre",
        subtitle:
            "À l’avenir, Impro Impact proposera des ateliers de sensibilisation sur le genre.",
        content: "À suivre...",
    },
}

export default content
